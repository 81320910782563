import React, {useState} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import EditButton from "./EditButton";
import ImageDialog from "./ImageDialog";
import UsersButton from "./UsersButton";
import UserModal from "./UserModal";

const CompanyList = ({ companies }) => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
    const [userModalOpen, setUserModalOpen] = useState(false); // State to control user modal

    const handleEditClick = (empresaId) => {
        setSelectedEmpresaId(empresaId);
        setDialogOpen(true);
    };

    const handleUserModalOpen = (empresaId) => {
        setSelectedEmpresaId(empresaId);
        setUserModalOpen(true);
    };

    const handleCloseUserModal = () => {
        setUserModalOpen(false);
        setSelectedEmpresaId(null);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedEmpresaId(null);
    };

    return (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Cnpj</TableCell>
                        <TableCell>Razão Social</TableCell>
                        <TableCell>Ação</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companies.map((company, index) => (
                        <TableRow key={index}>
                            <TableCell>{company.cnpj}</TableCell>
                            <TableCell>{company.razaoSocial}</TableCell>
                            <TableCell>
                                <EditButton onClick={() => handleEditClick(company.id)} />
                                <UsersButton onClick={() => handleUserModalOpen(company.id)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ImageDialog open={dialogOpen} onClose={handleCloseDialog} empresaId={selectedEmpresaId} />
            <UserModal open={userModalOpen} onClose={handleCloseUserModal} empresaId={selectedEmpresaId} />

        </TableContainer>
    );
};

export default CompanyList;
