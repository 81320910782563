import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Snackbar, Alert, TextField, Pagination } from '@mui/material';
import api from '../services/api';
import {LockReset} from "@mui/icons-material";

const UserModal = ({ open, onClose, empresaId }) => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        if (empresaId) {
            fetchUsers();
        }
    }, [empresaId, search, page]);

    const fetchUsers = () => {
        api.get(`/api/empresa/${empresaId}/usuarios`, {
            params: {
                search: search,
                page: page - 1,
                size: 10
            }
        })
            .then(response => {
                setUsers(response.data.content);
                setTotalPages(response.data.totalPages);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleResetPassword = (userId) => {
        api.put(`/api/auth/resetar-senha/${userId}`)
            .then(() => {
                setSnackbarMessage('Senha resetada com sucesso');
                setSnackbarSeverity('success');
            })
            .catch(error => {
                setSnackbarMessage('Erro ao resetar a senha');
                setSnackbarSeverity('error');
                console.error('Error resetting password:', error);
            })
            .finally(() => {
                setSnackbarOpen(true);
            });
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Usuários da Empresa</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Buscar usuários"
                    variant="outlined"
                    value={search}
                    onChange={handleSearchChange}
                    sx={{ mb: 2 }}
                    style={{ marginTop: '10px' }}
                />

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Usuário</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell align="center">Ação</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell align="center">
                                        <Button
                                            variant="contained"
                                            startIcon={<LockReset />}
                                            color="primary"
                                            onClick={() => handleResetPassword(user.username)}
                                        >
                                            Resetar Senha
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box display="flex" justifyContent="center" mt={2}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Fechar</Button>
            </DialogActions>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default UserModal;